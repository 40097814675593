import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { BehaviorSubject, catchError, Observable, of, switchMap, throwError } from 'rxjs';
import { PermissionsService } from '../services/identity/permissions.service';
import { LStorageService } from '../services/others/lstorage.service';
import { Ability } from '@casl/ability';
import { environment } from 'environments/environment';
import { StorageKeysConstants } from '../constants/storage-keys.constants';
import { Credentials } from '../interfaces/identity/credentials.interface';
import { ForgotPassword } from '../interfaces/identity/forgot-password.interface';
import { RegisterData } from '../interfaces/identity/register.interface';
import { ResetPassword } from '../interfaces/identity/reset-password.interface';
import { SignInResponse } from '../interfaces/identity/sign-in-response.interface';
import { UserData } from '../interfaces/identity/user-data.interface';
import { CustomEncoder } from 'app/core/utils/custom-encoder';
import { User } from '../interfaces/identity/user.interface';
import { Permission } from '../interfaces/identity/permission.interface';

@Injectable({ providedIn: 'root' })
export class AuthService {
  private _authenticated: boolean = false;
  private _userSubject: BehaviorSubject<User> = new BehaviorSubject<User>(null);
  private _isRegistered: boolean = false;

  /**
   * Constructor
   */
  constructor(
    private _httpClient: HttpClient,
    private _lStorageService: LStorageService,
    private _permissionsService: PermissionsService,
    private _ability: Ability
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Setter for access token
   */
  set accessToken(token: string) {
    this._lStorageService.setItem(StorageKeysConstants.ACCESS_TOKEN, token);
  }

  /**
   * Getter for access token
   */
  get accessToken(): string {
    let accessToken = this._lStorageService.getItem(StorageKeysConstants.ACCESS_TOKEN) ?? '';
    return accessToken;
  }

  /**
   * Setter for refresh token
   */
  set refreshToken(refreshToken: string) {
    this._lStorageService.setItem(StorageKeysConstants.REFRESH_TOKEN, refreshToken);
  }

  /**
   * Getter for refresh token
   */
  get refreshToken(): string {
    return this._lStorageService.getItem(StorageKeysConstants.REFRESH_TOKEN) ?? '';
  }

  /**
   * Setter for remember me
   */
  set rememberMe(value: boolean) {
    this._lStorageService.setItem(StorageKeysConstants.REMEMBER_ME, JSON.stringify(value));
  }

  /**
   * Getter for remember me
   */
  get rememberMe(): boolean {
    return JSON.parse(this._lStorageService.getItem(StorageKeysConstants.REMEMBER_ME) ?? '');
  }

  /**
   * Setter for user
   */
  set user(user: User) {
    // Set data user
    this._setUserData(user);
  }

  /**
   * Getter for user
   */
  get user(): User {
    let user = this._lStorageService.getItem(StorageKeysConstants.USER) ?? '';
    return user ? JSON.parse(user) : null;
  }

  /**
   * Getter for authenticated user subject
   */
  get user$(): Observable<any> {
    return this._userSubject.asObservable();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  public confirmEmail = (route: string, user: string, token: string) => {
    let params = new HttpParams({ encoder: new CustomEncoder() });
    params = params.append('user', user);
    params = params.append('token', token);

    return this._httpClient.get(route, { params: params });
  };

  /**
   * Forgot password
   *
   * @param email
   */
  forgotPassword(email: ForgotPassword): Observable<any> {
    return this._httpClient.post(environment.apiItagueUrl + 'identity/accounts/recover-password', email);
  }

  /**
   * Reset password
   *
   * @param password
   */
  resetPassword(password: ResetPassword): Observable<any> {
    return this._httpClient.post(environment.apiItagueUrl + 'identity/accounts/restore-password', password);
  }

  /**
   * Sign in
   *
   * @param credentials
   */
  signIn(credentials: Credentials): Observable<any> {
    const url: string = environment.apiItagueUrl + 'identity/accounts/login';

    // Throw error, if the user is already logged in
    if (this._authenticated) {
      return throwError(() => new Error('User is already logged in.'));
    }

    return this._httpClient.post<SignInResponse>(url, credentials).pipe(
      switchMap((response: SignInResponse) => {
        this._processSignInResponse(response);

        // Store flag remember me
        this.rememberMe = credentials.rememberMe;

        // Return a new observable with the response
        return of(response);
      })
    );
  }

  /**
   * Sign in using the access token
   */
  signInUsingRefreshToken(): Observable<any> {
    const url: string = environment.apiItagueUrl + 'identity/accounts/refresh-token';

    if (this.refreshToken && this.refreshToken !== '') {
      // Renew token
      return this._httpClient
        .post<SignInResponse>(url, {
          refreshToken: this.refreshToken,
          rememberMe: this.rememberMe
        })
        .pipe(
          catchError(() => {
            this._clearStorage();

            // Return false
            return of(false);
          }),
          switchMap((response: SignInResponse) => {
            this._processSignInResponse(response);

            // Return true
            return of(true);
          })
        );
    } else {
      return of(false);
    }
  }

  /**
   * Get a new access token for a business unit
   */
  changeBusinessUnit(businessUnitGuid: string, businessUnitType: string): Observable<any> {
    const url: string = environment.apiItagueUrl + 'identity/accounts/change-business-unit';

    if (this.refreshToken && this.refreshToken !== '') {
      // Renew token
      return this._httpClient
        .post<SignInResponse>(url, {
          refreshToken: this.refreshToken,
          businessUnitType: businessUnitType,
          businessUnitGuid: businessUnitGuid,
          rememberMe: this.rememberMe
        })
        .pipe(
          catchError(() => {
            this._clearStorage();

            // Return false
            return of(false);
          }),
          switchMap((response: SignInResponse) => {
            this._processSignInResponse(response);

            // Return true
            return of(true);
          })
        );
    } else {
      return of(false);
    }
  }

  /**
   * Sign out
   */
  signOut(): Observable<any> {
    // Remove info from storage
    this._clearStorage();

    // Set the authenticated flag to false
    this._authenticated = false;

    // Set the registered flag to false
    this._isRegistered = false;

    // Clean user data
    this._userSubject.next(null);

    // Clean user permissions
    this._ability.update([]);

    // Return the observable
    return of(true);
  }

  /**
   * Sign up
   *
   * @param user
   */
  signUp(registerData: RegisterData): Observable<UserData> {
    return this._httpClient.post<UserData>(environment.apiItagueUrl + 'identity/accounts/register', registerData);
  }

  /**
   * Check the authentication status
   */
  check(): Observable<boolean> {
    // Check if the user is logged in
    if (this._authenticated) {
      return of(true);
    }

    // Check the access token availability
    if (!this.accessToken || this.accessToken === '') {
      this._clearStorage();
      return of(false);
    }

    // Check the access token expire date
    if (AuthUtils.isTokenExpired(this.accessToken)) {
      // sign in using refresh token
      return this.signInUsingRefreshToken();
    } else {
      //Set data when refresh
      this._authenticated = true;
      this._setUserData(this.user);
      return of(true);
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Clear all items from storage saved by app
   */
  private _clearStorage(): void {
    // Remove the access token from the local storage
    this._lStorageService.removeItem(StorageKeysConstants.ACCESS_TOKEN);

    // Remove the refresh token from the local storage
    this._lStorageService.removeItem(StorageKeysConstants.REFRESH_TOKEN);

    // Remove the remember me from the local storage
    this._lStorageService.removeItem(StorageKeysConstants.REMEMBER_ME);

    // Remove the user from the local storage
    this._lStorageService.removeItem(StorageKeysConstants.USER);
  }

  /**
   * Process sign in response
   * @param response
   */
  private _processSignInResponse(response: SignInResponse): void {
    // Store the access token in the local storage
    this.accessToken = response.token;

    // Store the access token in the local storage
    this.refreshToken = response.refreshToken;

    // Set the authenticated flag
    this._authenticated = response.isAuthSuccessful;

    // Set the registered flag
    this._isRegistered = response.isRegistered;

    // Set the user
    this.user = response.user;
  }

  /**
   * Set user data
   * @param user
   */
  private _setUserData(user: User): void {
    // Set the user
    this._userSubject.next(user);

    // Set permissions
    if (user.dependentRoles) {
      this._setPermissions(user.dependentRoles);
    } else {
      this.signOut();
    }

    this._lStorageService.setItem(StorageKeysConstants.USER, JSON.stringify(user));
  }

  /**
   * Set user permission
   * @param userRoles
   */
  private _setPermissions(userRoles: string[]): void {
    let ability = new Ability();

    // Subscribe to navigation data
    this._permissionsService.get(userRoles).subscribe((permissions: Permission[]) => {
      ability = new Ability(permissions);
      this._ability.update(ability.rules);

      return of(true);
    });
  }
}
