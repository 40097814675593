//import { NgxMatDateFormats } from "@angular-material-components/datetime-picker";
import { OptionTrueOrNo } from '../interfaces/others/option-true-or-no-interface'
import { Rol } from '../interfaces/identity/rol.interface'
import { Status } from '../interfaces/others/status.interface'

/**
 * Generic constants
 */
export abstract class GenericConstants {
  static readonly DEFAULT_LANGUAGE: string = 'es'

  /**
   * Languages available for the application
   */
  static readonly APP_LANGUAGES: string[] = ['en', 'es']

  /**
   * Default page size for table paginator
   */
  static readonly PAGE_SIZE_DEFAULT: number = 5

  /**
   * Page size options for table paginator
   */
  static readonly PAGE_SIZE_OPTIONS: number[] = [5, 10, 25, 100]

  /**
   * Yes or no options
   */
  static readonly YES_OR_NO: OptionTrueOrNo[] = [
    { id: true, name: 'yes' },
    { id: false, name: 'no' },
  ]

  /**
   * status options
   */
  static readonly STATUS: Status[] = [
    { id: 'Active', name: 'active' },
    { id: 'Inactive', name: 'inactive' },
  ]

  /**
   * Salt for encryption
   */
  static readonly SALT_ENCRYPTION: string = 'i?7=.46sc,)zC.'

  /**
   * Prefix for encryption
   */
  static readonly PREFIX_ENCRYPTION: string = 'byl7f&k$9'

  /**
   * Sufix for encryption
   */
  static readonly SUFIX_ENCRYPTION: string = '7Q^uYm'

  /**
   * Format for dates
   */
  static readonly MY_DATE_FORMAT = {
    parse: {
      dateInput: 'DD/MM/YYYY', // this is how your date will be parsed from Input
    },
    display: {
      dateInput: 'DD/MM/YYYY', // this is how your date will get displayed on the Input
      timeInput: 'hh:mm a',
      monthYearLabel: 'MMMM YYYY',
      dateA11yLabel: 'LL',
      monthYearA11yLabel: 'MMMM YYYY',
    },
  }
  static readonly MONTH_DATE_FORMAT = {
    parse: {
      dateInput: 'MM/YYYY',
    },
    display: {
      dateInput: 'MM/YYYY',
      monthYearLabel: 'MMM YYYY',
      dateA11yLabel: 'LL',
      monthYearA11yLabel: 'MMMM YYYY',
    },
  }

  /*
      static readonly CUSTOM_DATE_FORMATS: NgxMatDateFormats = {
        parse: {
          dateInput: "l, LTS"
        },
        display: {
          dateInput: "DD/MM/YYYY hh:mm a",
          monthYearLabel: "MMM YYYY",
          dateA11yLabel: "LL",
          monthYearA11yLabel: "MMMM YYYY"
        }
      };
      */

  /**
   * WATER SOURCES options
   */
  static readonly WATERSOURCESTYPE: any[] = [
    { id: 'River', name: 'river' },
    { id: 'Well', name: 'well' },
    { id: 'Lake', name: 'lake' },
    { id: 'Spring', name: 'spring' },
  ]
  static readonly WATERSOURCESACCESSFREQUENCY: any[] = [
    { id: 'Continuously', name: 'continuously' },
    { id: 'Does not have water service', name: 'noWaterService' },
    { id: 'Intermittent', name: 'intermittent' },
  ]
  static readonly WATERSOURCESUSEORPURPOSE: any[] = [
    { id: 'Crop', name: 'crop' },
    { id: 'Domestic use', name: 'domesticUse' },
  ]

  /**
   * FERTILIZERSTYPE options
   */
  static readonly FERTILIZERSTYPE: any[] = [
    { id: 'Organic', name: 'organic' },
    { id: 'Mineral', name: 'mineral' },
  ]
  static readonly PROVENANCE: any[] = [
    { id: 'Bought', name: 'bought' },
    { id: 'From the same farm', name: 'fromTheSameFarm' },
    { id: 'Other', name: 'other' },
  ]
  static readonly GENETIC: any[] = [
    { id: 'Pure', name: 'Pura' },
    { id: 'Mixed', name: 'Cruzada' },
  ]
  static readonly AGRICULTURALCYCLESTATUS: any[] = [
    { id: 'Active', name: 'active' },
    { id: 'Finished', name: 'finished' },
  ]

  static readonly DEFAULT_LATITUDE: number = 4.653429076344953
  static readonly DEFAULT_LONGITUDE: number = -74.0879362102077
  static readonly MILITARYNOTEBOOKTYPE: any[] = [
    { id: 'First', name: 'Primera' },
    { id: 'Second', name: 'Segunda' },
  ]
  static readonly YES_OR_NO_SPANISH: OptionTrueOrNo[] = [
    { id: true, name: 'Si' },
    { id: false, name: 'No' },
  ]

  static readonly ROLES: Rol[] = [
    { id: 'SuperAdmin', name: 'SuperAdmin' },
    { id: 'ExternalAuditor', name: 'ExternalAuditor' },
    { id: 'Registered', name: 'Registered' },
    { id: 'Administrator', name: 'Administrator' },
    { id: 'Organization', name: 'Organization' },
    { id: 'TechnicalAdvisor', name: 'TechnicalAdvisor' },
    { id: 'Producer', name: 'Producer' },
    { id: 'AgriculturalWorker', name: 'AgriculturalWorker' },
    { id: 'FarmExternalAuditor', name: 'FarmExternalAuditor' },
    { id: 'AttachedFarmAdministrator', name: 'AttachedFarmAdministrator' },
    { id: 'OrganizationAdministrator', name: 'OrganizationAdministrator' },
    { id: 'FarmAdministrator', name: 'FarmAdministrator' },
    { id: 'FarmTechnicalAdvisor', name: 'FarmTechnicalAdvisor' },
  ]

  static readonly COLORS: any[] = [
    { id: '#F60404', name: 'Rojo' },
    { id: '#04F675', name: 'Verde' },
  ]
  /**
   * DEFAULT_HOURS_SPENT_PER_DAY in activities
   */
  static readonly DEFAULT_HOURS_SPENT_PER_DAY: number = 8

  /**
   * DEFAULT_START_TIME in activities
   */
  static readonly DEFAULT_START_TIME: string = '06:00 am'

  /**
   * DEFAULT_END_TIME in activities
   */
  static readonly DEFAULT_END_TIME: string = '06:00 pm'

  /* Days options
   */
  static readonly DAYS_OPTIONS: any[] = [
    { id: 'sunday', name: 'Sunday' },
    { id: 'monday', name: 'Monday' },
    { id: 'tuesday', name: 'Tuesday' },
    { id: 'wednesday', name: 'Wednesday' },
    { id: 'thursday', name: 'Thursday' },
    { id: 'friday', name: 'Friday' },
    { id: 'saturday', name: 'Saturday' },
  ]

  /* Anomaly events status
   */
  static readonly ANOMALY_EVENTS_STATUS: any[] = [
    { id: 'Rechaza', name: 'Rechaza' },
    { id: 'Revisada', name: 'Revisada' },
  ]
}
